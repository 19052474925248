import { AsyncPipe } from '@angular/common';
import * as i0 from '@angular/core';
import { signal, Component, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import * as i1 from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorComponent, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { CrmLoadingComponent } from 'common-module/common';
import { CrmControlValueAccessorDirective, crmControlValueAccessorFactory, CrmBypassHtmlPipe } from 'common-module/core';
import { of, Subject } from 'rxjs';
import { CrmFormDataInputComponent, CrmFormDataWrapperComponent } from 'common-module/form/fragments';
import { v4 } from 'uuid';

/**
 * Default editor provider implementation
 */
function CrmEditorComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "editor", 1);
    i0.ɵɵlistener("ngModelChange", function CrmEditorComponent_Conditional_0_Template_editor_ngModelChange_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.handleChange($event));
    })("onInit", function CrmEditorComponent_Conditional_0_Template_editor_onInit_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.handleInit($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("init", ctx)("ngModel", ctx_r1.value);
  }
}
function CrmEditorComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "crm-loading");
  }
}
function CrmFormEditorComponent_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "crm-editor", 2);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("providerToken", ctx_r0.options.providerToken)("formControl", ctx_r0.control);
  }
}
function CrmFormEditorComponent_Conditional_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 3);
    i0.ɵɵpipe(1, "crmBypassHtml");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("innerHTML", i0.ɵɵpipeBind1(1, 1, ctx_r0.data), i0.ɵɵsanitizeHtml);
  }
}
class CrmEditorProviderImpl {
  constructor() {
    this.blockFormats = {
      Normal: 'p',
      'Header 1': 'h1',
      'Header 2': 'h2',
      'Header 3': 'h3',
      'Header 4': 'h4',
      'Header 5': 'h5',
      'Header 6': 'h6'
    };
    this.setup = _ => {};
  }
  init$() {
    return of(this.getOptions());
  }
  handleChange(_value) {}
  handleInit(_) {}
  getOptions() {
    return {
      plugins: this.setupPlugins(),
      toolbar: this.setupToolbar(),
      block_formats: this.setupBlockFormats(),
      valid_elements: undefined,
      entity_encoding: 'raw',
      setup: this.setup
    };
  }
  setupPlugins() {
    return ['lists', 'autoresize'];
  }
  setupToolbar() {
    return ['fontsize forecolor bullist | undo redo'];
  }
  setupBlockFormats() {
    return Object.entries(this.blockFormats).map(([key, value]) => [key, value].join('=')).join('; ');
  }
}
class CrmEditorComponent extends CrmControlValueAccessorDirective {
  constructor() {
    super(...arguments);
    this.init = signal(undefined);
  }
  ngOnInit() {
    super.ngOnInit();
    if (this.providerToken) {
      this.provider = this.injector.get(this.providerToken);
    } else {
      this.provider = new CrmEditorProviderImpl();
    }
    this.provider.init$().subscribe(init => {
      this.init.set(init);
    });
  }
  handleChange(content) {
    this.provider.handleChange(content);
    super.handleChange(content);
  }
  handleInit({
    editor
  }) {
    this.provider.handleInit(editor);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmEditorComponent_BaseFactory;
      return function CrmEditorComponent_Factory(t) {
        return (ɵCrmEditorComponent_BaseFactory || (ɵCrmEditorComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmEditorComponent)))(t || CrmEditorComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmEditorComponent,
      selectors: [["crm-editor"]],
      inputs: {
        providerToken: "providerToken"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([crmControlValueAccessorFactory(CrmEditorComponent)]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 1,
      consts: [[3, "init", "ngModel"], [3, "ngModelChange", "onInit", "init", "ngModel"]],
      template: function CrmEditorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, CrmEditorComponent_Conditional_0_Template, 1, 2, "editor", 0)(1, CrmEditorComponent_Conditional_1_Template, 1, 0);
        }
        if (rf & 2) {
          let tmp_0_0;
          i0.ɵɵconditional(0, (tmp_0_0 = ctx.init()) ? 0 : 1, tmp_0_0);
        }
      },
      dependencies: [EditorComponent, FormsModule, i1.NgControlStatus, i1.NgModel, CrmLoadingComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmEditorComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-editor',
      template: `
    @if (init(); as init) {
      <editor
        [init]="init"
        [ngModel]="value"
        (ngModelChange)="handleChange($event)"
        (onInit)="handleInit($event)"
      ></editor>
    } @else {
      <crm-loading></crm-loading>
    }
  `,
      providers: [crmControlValueAccessorFactory(CrmEditorComponent)],
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [EditorComponent, AsyncPipe, FormsModule, CrmLoadingComponent]
    }]
  }], null, {
    providerToken: [{
      type: Input
    }]
  });
})();
class CrmEditorModule {
  static forRoot() {
    return {
      ngModule: CrmEditorModule,
      providers: [{
        provide: TINYMCE_SCRIPT_SRC,
        useValue: 'tinymce/tinymce.min.js'
      }]
    };
  }
  static {
    this.ɵfac = function CrmEditorModule_Factory(t) {
      return new (t || CrmEditorModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CrmEditorModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmEditorModule, [{
    type: NgModule
  }], null, null);
})();
class CrmFormEditorComponent extends CrmFormDataInputComponent {
  constructor() {
    super(...arguments);
    this.options = {};
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmFormEditorComponent_BaseFactory;
      return function CrmFormEditorComponent_Factory(t) {
        return (ɵCrmFormEditorComponent_BaseFactory || (ɵCrmFormEditorComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmFormEditorComponent)))(t || CrmFormEditorComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormEditorComponent,
      selectors: [["crm-form-editor"]],
      inputs: {
        options: "options"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 6,
      vars: 8,
      consts: [[1, "crm-form-editor"], [3, "status", "error", "config"], [3, "providerToken", "formControl"], [3, "innerHTML"]],
      template: function CrmFormEditorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0)(1, "crm-form-data-input-wrapper", 1);
          i0.ɵɵpipe(2, "async");
          i0.ɵɵpipe(3, "async");
          i0.ɵɵtemplate(4, CrmFormEditorComponent_Conditional_4_Template, 1, 2, "crm-editor", 2)(5, CrmFormEditorComponent_Conditional_5_Template, 2, 3);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵproperty("status", i0.ɵɵpipeBind1(2, 4, ctx.status$))("error", i0.ɵɵpipeBind1(3, 6, ctx.error$))("config", ctx.config);
          i0.ɵɵadvance(3);
          i0.ɵɵconditional(4, ctx.mode() === "edit" ? 4 : 5);
        }
      },
      dependencies: [CrmFormDataWrapperComponent, CrmEditorComponent, ReactiveFormsModule, i1.NgControlStatus, i1.FormControlDirective, AsyncPipe, CrmBypassHtmlPipe],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormEditorComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-editor',
      template: `
    <div class="crm-form-editor">
      <crm-form-data-input-wrapper
        [status]="status$ | async"
        [error]="error$ | async"
        [config]="config"
      >
        @if (mode() === 'edit') {
          <crm-editor
            [providerToken]="options.providerToken"
            [formControl]="control"
          ></crm-editor>
        } @else {
          <div [innerHTML]="data | crmBypassHtml"></div>
        }
      </crm-form-data-input-wrapper>
    </div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CrmFormDataWrapperComponent, CrmEditorComponent, ReactiveFormsModule, AsyncPipe, CrmBypassHtmlPipe]
    }]
  }], null, {
    options: [{
      type: Input
    }]
  });
})();
/**
 * @deprecated
 */
class CrmFormEditorModule {
  static {
    this.ɵfac = function CrmFormEditorModule_Factory(t) {
      return new (t || CrmFormEditorModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CrmFormEditorModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CrmFormEditorComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormEditorModule, [{
    type: NgModule,
    args: [{
      imports: [CrmFormEditorComponent],
      exports: [CrmFormEditorComponent]
    }]
  }], null, null);
})();

/**
 * Function which returns editor form fragment
 *
 * @param name
 * @param config
 * @param options
 * @param layout
 * @param visible$
 */
const crmGetFormEditor = ({
  name,
  config,
  options,
  layout,
  visible$
}) => ({
  type: 'data-input',
  name,
  content: CrmFormEditorComponent,
  contentContext: {
    config: config ?? {},
    options: options ?? {}
  },
  layout,
  visible$
});
const icon$1 = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10.041 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591zm-5.041-15c-1.654 0-3 1.346-3 3v14c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3v-14c0-1.654-1.346-3-3-3h-14zm19 3v14c0 2.761-2.238 5-5 5h-14c-2.762 0-5-2.239-5-5v-14c0-2.761 2.238-5 5-5h14c2.762 0 5 2.239 5 5z"></path></svg>`;
const crmSetupCheckboxButton = (editor, options) => {
  const {
    id,
    disabled
  } = options;
  editor.ui.registry.addIcon('checkbox', icon$1);
  editor.ui.registry.addButton(id, {
    type: 'button',
    icon: 'checkbox',
    onAction: () => {
      const input = editor.dom.create('input', {
        name: v4(),
        type: 'checkbox',
        autocomplete: 'off',
        style: ['position: relative', 'top: 1px'].join(';')
      });
      if (disabled) {
        input.setAttribute('disabled', 'true');
      }
      editor.selection.setContent(input.outerHTML, {
        format: 'raw'
      });
      editor.selection.setContent(' ');
      editor.focus();
    }
  });
};
const crmSetTinymceOption = (editor, name, value) => {
  const currentOption = editor.options.get(name);
  if (currentOption == null) {
    editor.options.set(name, value);
    return;
  }
  if (typeof currentOption === 'string' && !currentOption.includes(value)) {
    editor.options.set(name, `${currentOption},${value}`);
    return;
  }
};
const CUSTOM_ELEMENTS = ['~placeholder'].join(',');
const crmSetupEntityProperty = (editor, options) => {
  const {
    entity,
    props,
    id
  } = options;
  crmSetTinymceOption(editor, 'custom_elements', CUSTOM_ELEMENTS);
  editor.ui.registry.addMenuButton(id, {
    type: 'menubutton',
    text: entity.name,
    fetch: callback => {
      callback(props.map(({
        key,
        name
      }) => ({
        type: 'menuitem',
        text: name,
        onAction: () => {
          const placeholder = editor.dom.create('placeholder', {
            'data-entity': entity.key,
            'data-property': key,
            'data-name': name,
            contenteditable: 'false',
            style: 'font-style: italic'
          }, name);
          editor.selection.setContent(placeholder.outerHTML, {
            format: 'raw'
          });
          editor.selection.setContent(' ');
          editor.focus();
        }
      })));
    }
  });
};
const icon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20v1h-4v-1h.835c.258 0 .405-.178.321-.422l-.473-1.371h-2.231l-.575-1.59h2.295l-1.362-4.077-1.154 3.451-.879-2.498.921-2.493h2.222l3.033 8.516c.111.315.244.484.578.484h.469zm-6-1h1v2h-7v-2h.532c.459 0 .782-.453.633-.887l-.816-2.113h-6.232l-.815 2.113c-.149.434.174.887.633.887h1.065v2h-7v-2h.43c.593 0 1.123-.375 1.32-.935l5.507-15.065h3.952l5.507 15.065c.197.56.69.935 1.284.935zm-10.886-6h4.238l-2.259-6.199-1.979 6.199z"></path></svg>`;
const crmSetupInputButton = (editor, options) => {
  const {
    id,
    disabled
  } = options;
  editor.ui.registry.addIcon('input', icon);
  editor.ui.registry.addButton(id, {
    type: 'button',
    icon: 'input',
    onAction: () => {
      const input = editor.dom.create('input', {
        name: v4(),
        type: 'text',
        autocomplete: 'off',
        style: ['border: none', 'outline: none', 'background-color: #f6f6f6', 'padding: 0', 'height: 28px'].join(';')
      });
      if (disabled) {
        input.setAttribute('disabled', 'true');
      }
      editor.selection.setContent(input.outerHTML, {
        format: 'raw'
      });
      editor.selection.setContent(' ');
      editor.focus();
    }
  });
};
const crmAddDataListNodeFilter = editor => {
  editor.parser.addAttributeFilter('data-list', nodes => {
    nodes.forEach(node => {
      switch (node.attr('data-list')) {
        case 'ordered':
          node.attr('style', 'list-style-type: decimal');
          break;
        case 'bullet':
          node.attr('style', 'list-style-type: disc');
          break;
      }
    });
  });
};
const crmAddEntityPropertyNodeFilter = editor => {
  editor.parser.addNodeFilter('placeholder', nodes => {
    nodes.forEach(node => {
      if (node.parent?.name === 'em') {
        node.parent.replace(node);
      }
      const spanChild = node.children().find(child => child.name === 'span');
      if (spanChild && spanChild.firstChild?.value) {
        node.children().forEach(child => child.remove());
        node.append(spanChild.firstChild);
      }
      node.attr('contenteditable', 'false');
    });
  });
};
const crmAddInputEditableNodeFilter = editor => {
  const subject$ = new Subject();
  editor.parser.addNodeFilter('input', nodes => {
    nodes.forEach(node => {
      if (node.name !== 'input') {
        return;
      }
      node.attr('disabled', null);
    });
    Promise.resolve().then(() => {
      editor.dom.select('input').forEach(input => {
        switch (input.type) {
          case 'text':
            crmInputChange(input, subject$);
            break;
          case 'checkbox':
            crmCheckboxChange(input, subject$);
            break;
        }
      });
    });
  });
  return subject$;
};
const crmInputChange = (input, subject$) => {
  input.addEventListener('input', event => {
    const target = event.target;
    const name = target.getAttribute('name');
    const value = target.value;
    if (name) {
      subject$.next({
        name,
        value
      });
    }
  });
};
const crmCheckboxChange = (input, subject$) => {
  input.setAttribute('contenteditable', 'true');
  input.style.cursor = 'pointer';
  input.addEventListener('change', event => {
    const target = event.target;
    const name = target.getAttribute('name');
    const value = target.checked;
    if (name) {
      subject$.next({
        name,
        value
      });
    }
  });
};
const crmAddQuillClassesAttributeFilter = editor => {
  editor.parser.addAttributeFilter('class', nodes => {
    nodes.forEach(node => {
      const classAttribute = node.attributes?.map['class'];
      if (classAttribute) {
        transformQuillClasses(node, classAttribute);
      }
    });
  });
};
const transformQuillClasses = (node, classes) => {
  const styles = classes.split(/\s+/g).reduce((result, name) => {
    const style = classToStyleMap[name];
    if (style) {
      result.push(classToStyleMap[name]);
    } else {
      console.warn(`Unknown class element '${name}' to transform to style during 'crmParseQuillClassesNodeFilter'`);
    }
    return result;
  }, []);
  node.attr('style', styles.join(';'));
  node.attr('class', null);
};
const indentModifier = indent => indent * 3 * 16;
const classToStyleMap = {
  'ql-align-justify': 'text-align: justify',
  'ql-align-center': 'text-align: center',
  'ql-align-right': 'text-align: center',
  'ql-direction-rtl': 'direction: rtl',
  'ql-indent-1': `padding-left: ${indentModifier(1)}px`,
  'ql-indent-2': `padding-left: ${indentModifier(2)}px`,
  'ql-indent-3': `padding-left: ${indentModifier(3)}px`,
  'ql-indent-4': `padding-left: ${indentModifier(4)}px`,
  'ql-indent-5': `padding-left: ${indentModifier(5)}px`,
  'ql-indent-6': `padding-left: ${indentModifier(6)}px`,
  'ql-indent-7': `padding-left: ${indentModifier(7)}px`,
  'ql-indent-8': `padding-left: ${indentModifier(8)}px`,
  'cms-ql-placeholder': 'font-style: italic',
  'cms-ql-input': ['border: none', 'outline: none', 'background-color: #f6f6f6', 'padding: 0', 'height: 28px'].join(';'),
  'cms-ql-checkbox': ['position: relative', 'top: 1px'].join(';')
};
const crmAddQuillTableFilter = editor => {
  editor.parser.addNodeFilter('table', nodes => {
    nodes.forEach(node => transformQuillTable(node));
  });
};
const transformQuillTable = table => {
  if (table.parent?.name === 'div') {
    table.parent.replace(table);
  }
  table.attr('class', null);
  table.attr('border', '1');
  table.attr('style', 'border-collapse: collapse; width: 100%');
  table.children().forEach(child => {
    switch (child.name) {
      case 'colgroup':
        transformColgroup(child);
        break;
      case 'tbody':
        transformBody(child);
        break;
    }
  });
};
const transformColgroup = colgroup => {
  const length = colgroup.children().length;
  colgroup.children().forEach(col => {
    if (col.name === 'col') {
      col.attr('width', `${100 / length}%`);
    }
  });
};
const transformBody = tbody => {
  tbody.children().forEach(tr => {
    if (tr.name === 'tr') {
      transformTr(tr);
    }
  });
};
const transformTr = tr => {
  tr.attr('data-row', null);
  tr.children().forEach(td => {
    if (td.name === 'td') {
      transformTd(td);
    }
  });
};
const transformTd = td => {
  td.attr('data-row', null);
  if (td.children().length === 1 && td.children()[0].name === 'p') {
    const child = td.children()[0].firstChild;
    if (child) {
      td.firstChild?.replace(child);
    }
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { CrmEditorComponent, CrmEditorModule, CrmEditorProviderImpl, CrmFormEditorComponent, CrmFormEditorModule, crmAddDataListNodeFilter, crmAddEntityPropertyNodeFilter, crmAddInputEditableNodeFilter, crmAddQuillClassesAttributeFilter, crmAddQuillTableFilter, crmGetFormEditor, crmSetTinymceOption, crmSetupCheckboxButton, crmSetupEntityProperty, crmSetupInputButton };
